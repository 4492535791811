import React from 'react';
import { Create, SimpleForm, TextInput, DateInput, ReferenceArrayInput, SelectInput, ArrayInput, SimpleFormIterator, required, AutocompleteArrayInput, ReferenceInput } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { voiceoverOptionText, expertOptionText, StatusChoices, DefaultValues } from './meta';

const create = (props) => (

	<Create {...props}>
		<SimpleForm defaultValues={DefaultValues}>
			<TextInput source="name" validate={required()} />
			<DateInput source="start_date" fullWidth={false} />
			<SelectInput source="status" choices={StatusChoices} validate={required()} fullWidth={false} />
			<RichTextInput source="instructions" multiline validate={required()} />

			<ReferenceInput source="pre_voiceover_id" reference="voiceovers" label="Pre-experience Voiceover">
				<SelectInput label="Pre-Experience Voiceover/Text" optionText={ voiceoverOptionText }/>
			</ReferenceInput>

			<TextInput source="question1" multiline rows={4} />
			<TextInput source="question2" multiline rows={4} />
			<TextInput source="question3" multiline rows={4} />
			<TextInput source="question4" multiline rows={4} />

			<ArrayInput source="links">
				<SimpleFormIterator inline>
					<TextInput source="title" />
					<TextInput source="url" />
				</SimpleFormIterator>
			</ArrayInput>

			<ReferenceArrayInput source="experts" reference="experts">
				<AutocompleteArrayInput label="Experts" optionText={ expertOptionText } />
			</ReferenceArrayInput>
			
		</SimpleForm>
	</Create>
);

export default create;
