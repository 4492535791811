export const StatusChoices = [
	{ id: 'active', name: 'Active' },
	{ id: 'inactive', name: 'Inactive' }
];

export const defaultValues = {
	"status": "active",
	"image": {
		default: true,
		title: "Default Profile Image",
		src: "https://stmatchmaker048851067649.blob.core.windows.net/matchmakerassets/layout/profile-icon.png"
	}
		
};