import {
	Datagrid,
	DateField,
	List,
	TextField,
	TopToolbar,
	CreateButton,
	EditButton

} from 'react-admin';

const ListActions = () => (
	<TopToolbar>
	  <CreateButton />
	</TopToolbar>
);
  
export const UserList = (props) => (
	<List {...props} empty={false} actions={<ListActions />}>
		<Datagrid rowClick="edit">
			<TextField source="email" />
			<TextField source="name" />
			<DateField source="last_login" showTime />
			<TextField source="role" />
			<EditButton />
		</Datagrid>
	</List>
);

export default UserList;