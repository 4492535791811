import {
	ReferenceInput,
	DateInput,
	Edit,
	ReferenceArrayInput,
	AutocompleteArrayInput,
	TextInput,
	SelectInput,
	required,
	ArrayInput,
	SimpleFormIterator,
	ReferenceManyField,
	TabbedForm,
	Datagrid,
	TextField,
	DateField,
	Pagination
} from 'react-admin';

import { RichTextInput } from 'ra-input-rich-text';

import { voiceoverOptionText, expertOptionText, StatusChoices } from './meta';


export const edit = () => (
	<Edit>
		<TabbedForm>
			<TabbedForm.Tab label="Details">
				<TextInput source="name" validate={required()} />
				<DateInput source="start_date" fullWidth={false} />
				<SelectInput source="status" choices={StatusChoices} validate={required()} fullWidth={false} />
				<RichTextInput source="instructions" multiline validate={required()} />

				<ReferenceInput source="pre_voiceover_id" reference="voiceovers" label="Pre-experience Voiceover">
					<SelectInput label="Pre-Experience Voiceover/Text" optionText={ voiceoverOptionText }/>
				</ReferenceInput>

				<TextInput source="question1" multiline rows={4} />
				<TextInput source="question2" multiline rows={4} />
				<TextInput source="question3" multiline rows={4} />
				<TextInput source="question4" multiline rows={4} />

				<ArrayInput source="links">
					<SimpleFormIterator inline>
						<TextInput source="title" />
						<TextInput source="url" />
					</SimpleFormIterator>
				</ArrayInput>

				<ReferenceArrayInput source="experts" reference="experts">
					<AutocompleteArrayInput label="Experts" optionText={ expertOptionText } />
				</ReferenceArrayInput>
			</TabbedForm.Tab>

			<TabbedForm.Tab label="Conversations">
				<ReferenceManyField reference="form-entries" target="event_id" pagination={<Pagination />}>
					<Datagrid>
						<TextField source="name" />
						<TextField source="company" />
						<TextField source="role" />
						<DateField source="updatedAt" showDate showTime />
					</Datagrid>
				</ReferenceManyField>
			</TabbedForm.Tab>
		</TabbedForm>
	</Edit>
);

export default edit;