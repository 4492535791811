import list from './list';
import edit from './edit';
import create from './create';

const Event = {
	list,
	edit,
	create
};

export default Event;