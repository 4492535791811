import create from './create';
import list from './list';
import edit from './edit';

const Expert = {
	create,
	edit,
	list
};

export default Expert;